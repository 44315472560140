import { get } from "lodash";
import { ApplicationState } from "./types";

export const getQuickLinks = (store: ApplicationState) =>
  get(store, "quickLinks.content.links", []);

export const getEnableCPPQuickLinks = (store: ApplicationState) =>
  get(store, "capi.featureToggles.enableCPPQuickLinks", false);

export const getEnablePromosStoryblok = (store: ApplicationState) =>
  get(store, "capi.featureToggles.enablePromosStoryblok", false);

export const getEnableSGWQuicklinks = (store: ApplicationState) =>
  get(store, "capi.featureToggles.enableSGWQuicklinks", false);
